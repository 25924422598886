<template>
<container-page>
  <bloc-simple class="p-3 mb-3 rounded" style="min-width:300px;">
    <h5>Gestion du matériel</h5>
    <div>
      <router-link
        to="/materiel/demandes"
        class="mt-1 cursor-pointer"
        :active-class="activeClass"
        :class="hoverClass"
        tag="div"
      >Demandes d'achats</router-link>
      <router-link
        to="/materiel/commandes"
        class="mt-1 cursor-pointer"
        :active-class="activeClass"
        :class="hoverClass"
        tag="div"
      >Commandes</router-link>
      <router-link
        to="/materiel/articles"
        class="mt-1 cursor-pointer"
        :active-class="activeClass"
        :class="hoverClass"
        tag="div"
      >Articles</router-link>
      <router-link
        to="/materiel/stocks"
        class="mt-1 cursor-pointer"
        :active-class="activeClass"
        :class="hoverClass"
        tag="div"
      >Stocks</router-link>
      <router-link
        to="/materiel/materiels"
        class="mt-1 cursor-pointer"
        :active-class="activeClass"
        :class="hoverClass"
        tag="div"
      >Matériels suivis</router-link>
      <router-link
      to="/materiel/fournisseurs"
      class="mt-1 cursor-pointer"
      :active-class="activeClass"
      :class="hoverClass"
      tag="div"
    >Fournisseurs</router-link>
    </div>
  </bloc-simple>
  <div>
    <div class="d-flex align-items-center" v-if="loading">
      <loader class="m-5"></loader>
      <h2 class="m-0">Chargement...</h2>
    </div>
  </div>
  <div class="flex-grow-1" v-if="!loading">
    <router-view></router-view>
  </div>
</container-page>
</template>
<script>
import { mapActions } from "vuex";
import ContainerPage from "@/components/containers/ContainerPage.vue";
import BlocSimple from "@/components/containers/ContainerBloc.vue";
import Loader from "@/components/bases/Loader.vue";

export default {
  components: {
    ContainerPage,
    BlocSimple,
    Loader
  },
  data() {
    return {
      loading:false
    };
  },
  mounted() {
    this.getAll();
    this.getData();
  },
  methods: {
    ...mapActions({
      getAll: "materiel/getAll",
      getData: "materiel/getData"
    })
  },
  computed: {
    activeClass: function() {
      if (!this.$store.getters["isDarkMode"]) {
        return "stroke-red text-danger";
      } else {
        return "stroke-red text-primary";
      }
    },
    hoverClass: function() {
      if (!this.$store.getters["isDarkMode"]) {
        return "cursor-hover-light";
      } else {
        return "cursor-hover-dark";
      }
    }
  },
};
</script>
<style></style>
